<template>
  <v-card
    class="report-mar-card"
    :loading="cardLoading"
    elevation="0"
    :class="[{ 'background-none': pDisableBackgroundColor }, pContentPadding]"
  >
    <v-card-title v-if="pTitleIsVisible" class="pl-0">
      <span class="headline">
        {{
          $localizationService.localize("report_mar.title", [
            currentSubject && !loadingSubject
              ? currentSubject.requests.length
              : $localizationService.localize("common.loading"),
          ])
        }}
      </span>
    </v-card-title>
    <v-tabs v-model="tabControl">
      <v-tab
        v-for="tab in tabs"
        :key="tab.header"
        :disabled="
          !currentSubject ||
          !getItemsVirtualScroll(currentSubject.requests, tab).length
        "
      >
        {{ tab.header }}
      </v-tab>
    </v-tabs>

    <v-card-text
      class="pa-0 pl-1 card-height report-mar-card-content"
      :style="cardMaxHeight"
    >
      <v-container v-if="!loadingSubject" class="pa-0 pt-3 pb-1">
        <v-row class="ma-0">
          <v-col class="pb-0 pl-0 pr-0">
            <v-tabs-items
              v-model="tabControl"
              :class="{ 'background-none': pDisableBackgroundColor }"
            >
              <v-tab-item v-for="tab in tabs" :key="tab.header">
                <v-virtual-scroll
                  :style="scrollMaxHeight"
                  class="scroll"
                  :bench="5"
                  :items="
                    currentSubject
                      ? getItemsVirtualScroll(currentSubject.requests, tab)
                      : []
                  "
                  :marType="tab.value"
                  height="500"
                  :item-height="currentTabItemHeight(tab)"
                  ref="virtualScroll"
                >
                  <template v-slot:default="{ item }">
                    <component
                      ref="marAgent"
                      :is="requestAgents[item.type]"
                      :key="item.id"
                      :request="item"
                    ></component>
                  </template>
                </v-virtual-scroll>
              </v-tab-item>
            </v-tabs-items>
            <v-checkbox
              class="ma-2 mb-1 ml-0 pt-0 ignore-checkbox shrink"
              hide-details
              v-model="tabs[tabControl].autoIgnoreControl"
              :label="
                $localizationService.localize('report_mar.resolve_all', [
                  tabs[tabControl].header,
                ])
              "
              @change="onAutoIgnoreCheckboxChange($event)"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-container>
      <v-container
        v-else
        class="d-flex justify-center align-center loader-container"
      >
        <v-progress-circular
          :size="70"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-container>
    </v-card-text>

    <v-card-actions class="pa-0">
      <v-container
        v-if="!loadingSubject && solutionsBarLastTarget == currentSubject"
        class="pa-0 d-flex fix-bm"
      >
        <div
          v-for="(entry, index) in solutionsBarData"
          :key="entry.request.id"
          :class="getClassesScrollToRowItem(entry, index)"
          class="solution flex-grow-1"
          @click="scrollToRequest(index)"
        ></div>
      </v-container>
      <v-spacer></v-spacer>
      <div class="d-flex" v-if="pDefaultActionButtonsIsVisible">
        <v-btn text @click="closeDialog()" :disabled="sendingSolutions">{{
          $localizationService.localize("btn.close")
        }}</v-btn>
        <v-btn
          text
          @click="save()"
          color="primary"
          :disabled="sendingSolutions || !allSolutionsComplete"
          >{{ $localizationService.localize("btn.save") }}</v-btn
        >
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import debounce from "lodash/debounce";
import { mapGetters, mapState } from "vuex";
import Vue from "vue";
import { IS_MOBILE } from "../utils/utils";

const locService = Vue.prototype.$localizationService;

const DRIVER_SELECTION_REQUEST = {
  header: locService.localize("report_mar.tab.drivers"),
  value: "DriverSelectionRequest",
  height: {
    standard: 130,
    mobile: 270,
  },
  tabControl: 0,
};
const VEHICLE_SELECTION_REQUEST = {
  header: locService.localize("report_mar.tab.vehicles"),
  value: "VehicleSelectionRequest",
  height: {
    standard: 130,
    mobile: 270,
  },
  tabControl: 1,
};
const REPORT_ITEM_PROPERTIES_FIX_REQUEST = {
  header: locService.localize("report_mar.tab.incorrect_data"),
  value: "ReportItemPropertiesFixRequest",
  height: {
    standard: 100,
    mobile: 130,
  },
  sortBy: "rowNumber",
  tabControl: 2,
};
export default {
  name: "ReportMar",

  props: {
    pContentPadding: String,
    pHideLoading: Boolean,
    pTitleIsVisible: {
      type: Boolean,
      default: true,
    },
    pCardMaxHeight: Number,
    pDisableBackgroundColor: {
      type: Boolean,
      default: false,
    },
    pDefaultActionButtonsIsVisible: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    tabControl: 0,
    tabs: [
      DRIVER_SELECTION_REQUEST,
      VEHICLE_SELECTION_REQUEST,
      REPORT_ITEM_PROPERTIES_FIX_REQUEST,
    ],
    solutionsBarDataCache: [],
    solutionsBarLastTarget: undefined,

    solutionTypeManual: "manual",
    solutionTypeAutoIgnore: "auto_ignore",
    solutionTypeAutoGenerated: "auto_generated",
  }),

  watch: {
    tabControl() {
      this.recalcSolutionsBarData();
    },

    loadingSubject() {
      this.recalcSolutionsBarData();
    },

    currentSubject() {
      const foundTab = this.tabs.find((marType) => (
        this.currentSubject.requests.some((it) => it.type === marType.value)
      ));

      if (foundTab) {
        this.tabControl = foundTab.tabControl;
        return;
      }

      this.closeDialog();
    },
  },

  computed: {
    ...mapState("marModule", [
      "sendingSolutions",
      "currentSubject",
      "requestAgents",
      "currentRequestsComponents",
      "loadingSubject",
      "autoIgnoreRequests",
      "subSystemLoading",
    ]),
    ...mapGetters("marModule", ["allSolutionsComplete"]),

    cardLoading() {
      return (this.sendingSolutions && !this.pHideLoading)
        || this.subSystemLoading;
    },

    solutionsBarData() {
      this.$nextTick(function () {
        this.recalcSolutionsBarDataWithDebounce();
      });
      return this.solutionsBarDataCache;
    },

    cardMaxHeight() {
      return `maxHeight: ${this.pCardMaxHeight}vh`;
    },

    scrollMaxHeight() {
      return `maxHeight: ${this.pCardMaxHeight - 10}vh`;
    },
  },

  methods: {
    getClassesScrollToRowItem(entry, index) {
      const classes = [];

      if (index == 0) {
        classes.push("rounded-l-lg");
      }

      if (index == this.solutionsBarData.length - 1) {
        classes.push("rounded-r-lg");
      }

      if (entry.solution) {
        if (entry.solutionType == this.solutionTypeAutoIgnore) return [...classes, "grey darken-1"];

        return entry.solution.specialClass
          ? [...classes, entry.solution.specialClass || "green"]
          : [...classes, "green"];
      }

      return [...classes, "red"];
    },

    currentTabItemHeight(tab) {
      return IS_MOBILE ? tab.height.mobile : tab.height.standard;
    },

    recalcSolutionsBarData() {
      this.solutionsBarDataCache = this.currentSubject
        ? this.getItemsVirtualScroll(
          this.currentSubject.requests,
          this.tabs[this.tabControl],
        ).map((request) => this.getSolutionBarDataUnit(request))
        : [];

      this.solutionsBarLastTarget = this.currentSubject;
    },

    onAutoIgnoreCheckboxChange(newValue) {
      const type = this.tabs[this.tabControl].value;

      if (newValue) {
        this.$mar.updateAutoIgnoreRequests([
          ...this.autoIgnoreRequests,
          ...this.currentSubject.requests.filter(
            (suspect) => suspect.type == type
            || (suspect.type == "MissingStationForShiftReportSelectionRequest" && type == REPORT_ITEM_PROPERTIES_FIX_REQUEST.value),
          ),
        ]);
      } else {
        let cleanedRequests = this.autoIgnoreRequests.filter((suspect) => suspect.type != type);

        if (type == REPORT_ITEM_PROPERTIES_FIX_REQUEST.value) {
          cleanedRequests = cleanedRequests.filter((it) => it.type != "MissingStationForShiftReportSelectionRequest");
        }

        this.$mar.updateAutoIgnoreRequests(cleanedRequests);
      }

      this.recalcSolutionsBarData();
    },

    getSolutionBarDataUnit(request) {
      const marComponent = this.currentRequestsComponents[request.id];

      const canBeAutoIgnored = !!this.autoIgnoreRequests.find(
        (suspect) => suspect.id == request.id,
      );

      let solutionType = marComponent
        ? this.solutionTypeManual
        : this.solutionTypeAutoGenerated;
      let solution = marComponent
        ? marComponent.currentInstance.solution || marComponent.lastSolution
        : request.getAutoGeneratedSolution();

      if (!solution && canBeAutoIgnored && request.getAutoIgnoreSolution()) {
        solutionType = this.solutionTypeAutoIgnore;
        solution = request.getAutoIgnoreSolution();
      }

      return {
        request,
        solutionType,
        solution,
      };
    },

    getItemsVirtualScroll(requests, tab) {
      let items = requests.filter((item) => item.type == tab.value
      || (item.type == "MissingStationForShiftReportSelectionRequest" && tab.value == REPORT_ITEM_PROPERTIES_FIX_REQUEST.value));

      if (tab.sortBy) {
        items = items.sort((a, b) => (typeof a[tab.sortBy] == "string"
          ? a[tab.sortBy].localeCompare(b[tab.sortBy])
          : a[tab.sortBy] - b[tab.sortBy]));
      }

      return items;
    },

    scrollToRequest(index) {
      const vScroll = this.$refs.virtualScroll.find(
        (item) => item.$attrs.marType == this.tabs[this.tabControl].value,
      );
      if (vScroll.$el) {
        vScroll.$el.scrollTo({
          top: this.currentTabItemHeight(this.tabs[this.tabControl]) * index,
          behavior: "smooth",
        });
      }
    },

    clean() {
      this.tabControl = 0;
      this.tabs.forEach((tab) => {
        tab.autoIgnoreControl = false;
      });
      this.solutionsBarDataCache = [];
    },

    save() {
      this.$emit("onSaveDataFromDialog");
    },

    closeDialog() {
      this.$emit("onCloseDialog");
    },
  },

  mounted() {
    this.recalcSolutionsBarDataWithDebounce = debounce(function () {
      this.recalcSolutionsBarData();
    }, 250);
  },
};
</script>

<style scoped lang="scss">
.background-none {
  background: none !important;
}

.solution {
  height: 20px;
  cursor: pointer;
  background-color: currentColor;
  border: 1px solid currentColor;
}

.solution:hover {
  border: 1px solid black !important;
}

.fix-bm {
  transform: translateY(-1px);
}

.loader-container {
  min-height: 500px;
}

.scroll {
  max-height: 60vh;
  overflow-x: hidden;
}
</style>

<style lang="scss">
.light-local-user-theme {
  .report-mar-card {
    .v-tabs .v-item-group {
      background: var(--v-secondary-base) !important;
    }
  }
}
.report-mar-card {
  .partial-highlight-text {
    color: orange;
  }
  .partial-auto-match-border-color {
    .v-input__slot::before {
      -o-border-image: repeating-linear-gradient(to right,
        orange 0px,
        orange 2px,
        transparent 2px, transparent 4px) 1 repeat !important;
      border-image: repeating-linear-gradient(to right,
        orange 0px,
        orange 2px,
        transparent 2px, transparent 4px) 1 repeat !important;
    }
  }
  .v-tabs.background-none .v-item-group {
    background: none !important;
  }
  .report-mar-card-content {
    .ignore-checkbox {
      max-width: fit-content;

      i {
        font-size: 20px !important;
      }

      .v-label {
        font-size: 14px;
      }

      .v-input--selection-controls__ripple {
        height: 28px;
        width: 28px;
        left: -9px;
        top: -9px;
      }
    }
  }
}
</style>
